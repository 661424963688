import React from "react"
import Layout from "../components/layout"

const About = () => {
  return   <Layout>
    <section id="mainTitle" style={{background: 'white', color: 'black'}}>
      <div className="row">
        <div className="twelve columns">
          <br />
          <h1 className="responsive-headline"  style={{color: 'black'}}>PRIVACY POLICY</h1>
          <br />

          <div>
            <p>
              Your privacy is important to us. We care about your personal data and undertake to carefully store user data and process it in compliance with the applicable regulations on personal data protection.
            </p>
            <p>
              By visiting this Website, registering for one of our events, cooperating with us, subscribing to our services or otherwise interacting with us we may collect and process personal data about you. We are strongly committed to protect and keep private any information so collected and to always comply with applicable legislation.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Data Controller</h2>
          </div>
          <div>
            <p>
              Controller: <a style={{color: "black"}} href="https://ineor.si/">Ineor Information Technologies d.o.o.,</a> BRN: 6025170000, Teslova ulica 30, 1000 Ljubljana (hereinafter referred to as “Controller”). E-mail address: i(at)ineor.si.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Legal basis and the purposes for processing</h2>
          </div>
          <div>
            <p>
              We may collect and process personal data from you directly when you fill out a form, purchase a service, communicate with us via e-mail or social media or from publicly accessible sources and process it for one of the following purposes pursuant to the following legal basis:
              Your consent to the processing for specific purposes:
              We process personal information – such as name, e-mail address, address, telephone, your company and position – you provide us for subscribing to our e-mail notifications and/or newsletters, as well as communication data that you send to us either through e-mail, social media, or other posting and/or communication method. We process this data for communicating with you, to deliver you relevant content, and for the purpose of sending you marketing, content and/or e-mails. You may opt-out of such communications at any time through the ‘unsubscribe’ button in each newsletter or contacting us via e-mail i(at)ineor.si.
              Fulfillment of contractual obligations:
              We process your personal information – such as name, address, personal identification and/or transaction account number, and other data needed for fulfillment of our cooperation contract obligation – that is necessary for the performance of a contract to which the data subject is party or to take steps at the request of the data subject prior to entering into a contract with us.
              Fulfillment of legal obligations to which the Controller is subject:
              We process your personal information – such as name, address, personal identification and other data needed – that is necessary for compliance with a legal obligation to which we are subject, such as taxation purposes.
              To improve our Website:
              This information may include your IP address, geographical location, device information, browser type, referral source, length of visit, operating system, number of page views, which pages you viewed and similar information. For more information on cookies please refer to our Cookies policy.
              To comply with or abide by legal obligations or requirements
              For recruiting new employees:
              This information may include your name, address, email address, phone number, date of birth, information regarding your education and experiences, CV, photo and other relevant information.
              Apart and additionally to the above we will process your personal data on the following legal bases:
              the performance of a contract with you in compliance with Article 6(1)(b) GDPR, for example where you have a business relationship with us, or where you have registered to attend or exhibit at an event or our service;
              our legitimate interest in compliance with Article 6(1)(f) GDPR, for example our desire to improve the Website; to keep you up to date on our business activities and events;
              your consent in compliance with Article 6(1)(a) GDPR, for example when you accept the use of cookies on the Website;
              to comply with a legal obligation to which we are subject in compliance with Article 6(1)(c) GDPR.

            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Children’s Online Privacy Protection Act</h2>
          </div>
          <div>
            <p>
              We are following the requirements of the Children’s Online Privacy Protection Act. We will not intentionally collect any information from anyone under 16 years of age. Our Website, products and services are all intended for people who are at least 16 years old or older. Our advertisements and other communication does not target children.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Transmission of the data to third countries</h2>
          </div>
          <div>
            <p>
              Your personal information will never be transmitted to recipients who are located outside the EU/EEA (”third countries”) from our part unless otherwise stated. In any such case, we ensure safeguards required by the GDPR.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Anti-Spam Policy</h2>
          </div>
          <div>
            <p>
              We comply with EU Anti-Spam legislation. If you opt-in to receive our newsletter, marketing, or other communications, the option to unsubscribe will be included in every e-mail you will receive from us.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Third party links</h2>
          </div>
          <div>
            <p>
              Our Website may contain links to third party websites. Unless otherwise stated, this Privacy policy only covers information that we collect from you on this Website. Any other link you may choose to follow will be covered by the privacy policy of that specific landing Website. You acknowledge and accept that we are not responsible for the privacy policies/practices of third parties.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Time of retention of personal data</h2>
          </div>
          <div>
            <p>
              We will not keep your personal data longer than necessary to fulfill purposes we collected them for or received or to comply with any applicable legal requirements. In case we process your personal data on the basis of your consent, we shall process it until you withdraw your consent. In case we process your data for marketing purposes, we shall process it until you object to such processing, Upon termination of purpose, withdrawal of consent or objection, you data will be deleted or otherwise disposed of in a safe and secure manner.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Our commitment to the security of your personal information</h2>
          </div>
          <div>
            <p>
              We take precautions to protect your information from loss, unauthorized access, misuse, or disclosure, alteration, and destruction. We may allow access to your personal data only to our employees, contractors, and service providers that need to know such data to perform necessary business services. Personal data will be kept confidential and employees and/or contractors will only have access to it when necessary. We have taken appropriate technical and organizational measures to protect the information systems on which your personal data is stored and we require our data processors to protect your personal data by contractual means.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Your rights relating to the processing of your personal data</h2>
          </div>
          <div>
            <p>
              Subject to certain exceptions and restrictions set out in applicable legislation, you enjoy the right to be informed relating to processing of your data, request access and obtain your personal data, to have your personal data rectified, deleted, or processing thereof restricted, to object to the processing and to data portability. You also have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects or affects you significantly.
            </p>
            <p>
              Where our processing of your personal data is based on your consent you have the right to withdraw your consent at any time without affecting the lawfulness of processing based on consent before its withdrawal.
            </p>
            <p>
              If you want to exercise any of your rights as described above or have any question to that, please contact us by sending an email to i(at)ineor.si.
            </p>
            <p>
              You also have the right to lodge a complaint with the competent supervisory authority.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Cookies and links to other websites</h2>
          </div>
          <div>
            <p>
              We use cookies and similar technologies to ensure that you get the most out of our Website, to collect information on how you use our Website and to track your online behavior. The type of information gathered may include origin of the website visitor or email recipient, type of browser, operating system and search engine used, and length of visit on the webpage or email message. For more information about how we use cookies and similar technologies and how you can control them, please refer to our Cookie policy.
            </p>
            <p>
              The Website may contain links to third party websites. We disclaim any control over, relationship with, or endorsement of these sites and shall not be liable for any damages arising from the content of such websites. Links to other websites are provided only as a convenience and we encourage that you read these third-party websites’ Terms of Use and Privacy Statements.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Validity of the Policy</h2>
          </div>
          <div>
            <p>
              The Privacy Policy is published on our Website and applies from 18.03.2019 onwards.
            </p>
            <p>
              We reserve the right to update or change our Privacy policy at any time. Updated privacy policies will be updated on our relevant Website, or provided to you at your request.
            </p>
            <p>
              <a style={{color: "black"}} href="https://ineor.si/">Ineor</a><br/>
              Last revision: 18.03.2019
              Prepared: 18.03.2019
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
};

export default About; 